@import url("https://use.typekit.net/dsl7rvi.css");
/* @import url("https://use.typekit.net/dsl7rvi.css"); */

body {
  margin: 0;
  font-family: 'Degular';
  /* font-family: 'Lust'; */
 
  font-style: normal !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@tailwind base; 
html {
  scroll-behavior: smooth;
}

.content{
  height: 100vh;
}
.textcontent{
  position:absolute;
  top:50%;
  left:50%;
  padding: 5px 15px;
  transform:translate(-50% -50%);
  background: transparent;
  color: white;
  box-sizing: 20px;
  font-size: 50px;
}

.home.carousel-indicators [data-bs-target] {
  background-color: var(--bg-primary);
  width: 2.5rem;
  height: 0.31rem;
}
.home.carousel-inner .carousel-item active {
  align-items: center;
  justify-content: center;
  text-align: center;
}
.carousel.carousel-slider{
  height: 100% !important;
}
@media only screen and (max-width: 600px) {
  .carousel.carousel-slider{
    height: 80% !important;
  }


  @media only screen and (min-width: 600px) 
  {
    .carousel.carousel-slider{
      height: 80% !important;
    }
  }

 
  /* @media only screen and (min-width: 768px) {
    .carousel.carousel-slider{
      height: 30% !important;
    }
  }
  
 
  @media only screen and (min-width: 992px) {
    .carousel.carousel-slider{
      height: 80% !important;
    }
  }
  
 
  @media only screen and (min-width: 1200px) {
    .carousel.carousel-slider{
      height: 80% !important;
    }
  } */



}
/* .lust{
  font-family:"lust-text",serif;
  font-weight:500;
  font-style:normal;
} */

table, th, td {
  border:1px solid black;
  justify-content: center;
  text-align: center;
 
}

.heights{
height: 725px;
}

.heights1{
  height: 700px;
  }

  .heights2{
    height: 653px;
    }
  
    .heights3{
      height: 938px;
      }

      .heights4{
        height: 700px;
        }

@tailwind components;
@tailwind utilities;

@layer components {
  /* .input-details {
    @apply  pl-3 h-full w-full rounded font-sans font-normal text-[#333333] placeholder-[#c3c3c3];
  }

  .input-details-error {
    @apply  pl-3 h-full w-full rounded font-sans font-normal text-[#333333] placeholder-[#c3c3c3];
  }

  .input-div-enquiry-details {
    @apply border-[0.25px] border-[#707070] border-opacity-70 h-14 rounded mb-8
  } */

  .input-div-enquiry-details-error {
    @apply border-[0.25px] border-[#FF0000] border-opacity-70 h-11 rounded mb-8
  }
  .input-div-enquiry-details-errors {
    @apply border-[0.25px] border-[#FF0000] border-opacity-70 h-10 rounded mb-8
  }



  /* .input-message-div-enquiry-details {
    @apply border-[0.25px] border-[#707070] border-opacity-70 h-20 rounded mb-8
  } */

  .label-enquiry-tollfree {
    @apply text-2xl text-[#FFFFFF] mb-3 font-['Degular'] font-normal
  }

  .asterisk::-moz-placeholder {
    @apply text-[#f00] opacity-100
  }
}



